import React from 'react';
import  './resume.css';
import Footer from '../components/Footer';

function Resume() {
    
    return(
    
        <body>
        <div className="w3-content w3-margin-top maxwidth w3-roboto">
        <div class="w3-row-padding">
            <div className = "w3-third">
                <div class="w3-white w3-text-grey w3-margin-bottom w3-card-4">
                <div class="w3-display-container">
                <center><img src="https://alex-mydata.s3.amazonaws.com/me.jpeg" style={{width:"100%"}}alt="Avatar"></img></center>
                    <div class="w3-display-bottomleft w3-container w3-text-white">
                    <h2 class="w3-roboto">Alex de Jesus</h2>
                    </div>
                </div>
                <br></br>
                <div class="w3-container w3-roboto">
                <p><i class="fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-blue"></i>Cybersecurity Engineer</p>
                <br></br>
                <p><i class="fa fa-home fa-fw w3-margin-right w3-large w3-text-blue"></i>Titusville, FL</p>
                <br></br>
                <p><i class="fa fa-envelope fa-fw w3-margin-right w3-large w3-text-blue"></i>mail@alexdj.me</p>
                <br></br>
                <p><i class="fa fa-linkedin fa-fw w3-margin-right w3-large w3-text-blue"></i><a href="https://www.linkedin.com/in/alex-de-jesus-686655a/">My LinkedIn</a></p>
                <hr></hr>
                <p class="w3-large"><b><i class="fa fa-user fa-fw w3-margin-right w3-text-blue"></i>About Me</b></p>
                <br></br>
               <p>Exprienced cybersecurity professional with 15+ years in government and entertainment. Looking to pivot to engineering and cloud security.</p>
                <hr></hr>
                <p class="w3-large"><b><i class="fa fa-asterisk fa-fw w3-margin-right w3-text-blue"></i>Skills</b></p>
                <br></br>
                <p><b>Programming:</b><br></br> Python, HTML, CSS, JavaScript, GitLab</p><br></br>
                <p><b>AWS:</b> <br></br>S3, Lambda, API Gateway, Code Commit, Amplify, SNS</p><br></br>
                <p><b>Cybersecurity:</b> <br></br>Governance, Policies & Controls, Controls Assessment, Findings Management & Remediation</p><br></br>
                <p><b>Reporting & Dashboards:</b><br></br> Kibana, Confluence, Jira, RSA Archer.</p>
                
                <hr></hr>
                <p class="w3-large w3-text-theme"><b><i class="fa fa-certificate fa-fw w3-margin-right w3-text-blue"></i>Certifications</b></p>
                <br></br>
                <p>Certified Information Systems Security Professional (CISSP)</p>
                <br></br>
                <p>Certified Cloud Security Professional (CCSP)</p>
                <br></br>
                <p>AWS Certified Solutions Architect – Associate</p>
                <br></br>
                <p>Google Associate Cloud Engineer</p>
                
                <hr></hr>
                <p class="w3-large w3-text-theme"><b><i class="fa fa-globe fa-fw w3-margin-right w3-text-blue"></i>Languages</b></p>
                <br></br>
                <p>English & Spanish</p>
                <br></br>
                
            </div>
                </div>
            </div>
            <div className = "w3-twothird w3-roboto">
            <div class="w3-container w3-card w3-white w3-margin-bottom">
            <h2 class="w3-text-grey w3-padding-16 w3-roboto"><i class="fa fa-suitcase fa-fw w3-margin-right w3-xxlarge w3-text-blue"></i>Work Experience</h2>
            <div class="w3-container w3-roboto">
            <h5 class="w3-opacity w3-roboto"><b>Staff Information Security Engineer / The Walt Disney Company</b></h5>
            <h6 class="w3-text-blue w3-roboto"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Jun 2022 - <span class="w3-tag w3-blue w3-roboto w3-round">Current</span></h6>
            <p class="w3-roboto">Provide efficiencies, cost-savings, and enhanced security posture by automating our processes and services. Levaraging technology like AWS, Python, REST API, etc. Partnering with the business to understand gaps and build solutions.</p>
            <hr></hr>
            </div>
            <div class="w3-container">
            <h5 class="w3-opacity w3-roboto"><b>Sr Security Specialist / The Walt Disney Company</b></h5>
            <h6 class="w3-text-blue w3-roboto"><i class="fa fa-calendar fa-fw w3-margin-right"></i>May 2014 - May 2022</h6>
            <p class="w3-roboto"><u>Corrective Action</u></p>
            <p class="w3-roboto">Tracked cybersecurity findings for the enterprise. Analyzed the findings to identify problems and propose solutions. Enhanced processes, metrics, reporting and dashboards to drive timely remediation. Reduced initial number of critical findings from 1000+ to less than 100 in the first year.</p>
            <br></br>
            <p class="w3-roboto"><u>Governance</u></p>
            <p class="w3-roboto">Formalized the new security controls catalog and policy based on NIST 800-53. Enhanced current standards to leverage new controls, policies, and requirements. Ensured that our policies, controls, and standards were continuously updated to stay ahead of current and future cyber threats.</p>
            <hr></hr>
            </div>
            <div class="w3-container">
            <h5 class="w3-opacity w3-roboto"><b>IT Security Analyst / AI Solutions</b></h5>
            <h6 class="w3-text-blue w3-roboto"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Jul 2013 - Apr 2014</h6>
            <p class="w3-roboto">Formalized security plans for all systems supporting NASA Ground Systems. Aligned NIST Security Lifeycle with NASA Engineering Lifeycle that provided efficiencies, cost and time savings. Perfomed controls and vulnerabilities assessments against systems and applications and ensured that gaps were remediated in a timely manner. </p><br></br>
            </div>
            <div class="w3-container">
            <h5 class="w3-opacity w3-roboto"><b>IT Specialist</b></h5>
            <h6 class="w3-text-blue w3-roboto"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Aug 2010 - Jun 2013</h6>
            <p class="w3-roboto"><u>Space & Naval Warfare Command (US Navy)</u></p>
            <p class="w3-roboto">Appointed Information Security Manager for 2 command and control applications. Lead a team that provided Certification and Accreditation (C&A), IV&V, Security Engineering, and other IT security services for DISA PEO-C2C. Established yearly Secure Application Development training for all development teams.</p><br></br>
            <p class="w3-roboto"><u>CIO/G6 (US ARMY)</u></p>
            <p class="w3-roboto">Selected for a competitive Leadership Development Program. Provided project coordination, security guidance on a continuous monitoring project. Participated in a multi-agency task force created to establish federal continuous monitoring standards. Formalized policies and procedures for new Classified Network (SIPR) Kiosks for NETCOM 9th Command. Completed Assessment & Authorization plan for a multi-national collaboration site hosted by Army South.</p><br></br>
            </div>
            <div class="w3-container">
            <h5 class="w3-opacity w3-roboto"><b>IT Analyst / The Walt Disney Company</b></h5>
            <h6 class="w3-text-blue w3-roboto"><i class="fa fa-calendar fa-fw w3-margin-right"></i>Nov 2008 - Jul 2010</h6>
            <p class="w3-roboto">Coordinated PCI remediation for multiple systems across WDW Parks and Resorts. Assisted in the creation and worked in a new Systems Operations Center for monitoring critical applications. Aided in reducing downtime of the sales composite by proactively identifying, documenting, and communicating symptoms of known problems.</p><br></br>
            </div>
        </div>
        <div class="w3-container w3-card w3-white">
        <h2 class="w3-text-grey w3-padding-16 w3-roboto"><i class="fa fa-certificate fa-fw w3-margin-right w3-xxlarge w3-text-blue"></i>Education</h2>
        <div class="w3-container w3-roboto">
          <h5 class="w3-opacity w3-roboto"><b>Master of Science in Management of Information Systems</b></h5>
          <p class="w3-roboto">University of Central Florida</p>
          <hr></hr>
        </div>
        <div class="w3-container w3-roboto">
          <h5 class="w3-opacity w3-roboto"><b>Bachelor of Science in Information Technology</b></h5>
          <p class="w3-roboto">Florida International University</p>
        </div>
        <br></br>
        <p></p>
      </div>
    </div>
    
        </div>
        <br></br>
        </div>
        <footer class="w3-container w3-blue w3-center w3-large w3-margin-top">
        <p><Footer /></p>
        </footer>
        </body>

    );
}

export default Resume;