import React, {useRef, useState} from 'react';
import {
  MapContainer,
  TileLayer,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './weather.css';

const WeatherApp = () => {
  // eslint-disable-next-line
  const [query, setQuery] = useState('');
  const [weather, setWeather] = useState({});
  const [lat, setLat] = useState('');
  const [lon, setLon] = useState('');

  const inputRef = useRef(null);
  const apikey = process.env.REACT_APP_KEY;
 
  const mapurl = `https://tile.openweathermap.org/map/precipitation/{z}/{x}/{y}.png?appid=${apikey}`;
  const center = {lat,lon};
  

  const handleClick = () => {
      
      getData();

  }
  const getData=()=>{
    const url = `https://api.openweathermap.org/data/2.5/weather?q=${inputRef.current.value}&appid=${apikey}&units=imperial`;
    if(inputRef.current.value!==""){
    fetch(url)
    .then(res => res.json())
    .then(result => {
      console.log(result);
      setWeather(result);
      try{
      setLat(JSON.parse(result.coord.lat));
     
      setLon(JSON.parse(result.coord.lon));
    }
    catch (error){
      alert("City not found! Please enter a new city!")
      inputRef.current.value="";
    }
    });}
    else{
      alert("Please enter a city!")
    }  
  };


  
  const onClear = () => {
    inputRef.current.value = "";
    window.location.reload(false);
    setWeather('');
    setQuery('');
    setLat('');
    setLon('');
  };

  return (
<body>
    <div className="app">
      <center><div class="w3-teal w3-roboto w3-block w3-large"><br></br>This project uses react-leaflet, OpenStreetMap and OpenWeatherMap<p>to generate a weather map based on the search city.</p><br></br></div></center>
      <br></br>
      <main>
      <center><div className="searchbox w3-roboto">
        <input 
        type = "text"
        ref={inputRef}
        className="searchbar"
        placeholder="Type in city and click submit"
        />
        
        <br></br>
              <input type="button" className="button" value=" Submit " onClick={handleClick}/>&nbsp;&nbsp;
      <input type="button" className="button" value=" Clear " onClick={onClear}/>
      <p class="w3-large"><br></br><b>To search a new city, press Clear.</b></p>
      </div></center>
      
      {(typeof weather.main != "undefined") ? (
      <div>
        <div className="locationbox">
          <div className="location">{weather.name}, {weather.sys.country}</div>
        </div>
        <div className="weatherbox">
          <div className="additional">
            <div className="feels">{Math.round(weather.main.temp)}&deg;F<p>Temp</p></div>
            <div className="feels">{weather.weather[0].main}<p>Forecast</p></div>
        </div>
        
        <center>
        
        <MapContainer style={{width: '70%', height: '50vh'}} dragging={false} className="leaflet-container" center={center} zoom={10} scrollWheelZoom={false}>   
        <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
       
        />"

    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url={mapurl}
    />

    </MapContainer></center>
        
        <div className="weatherbox">
          <div className="additional">
            <div className="feels">{Math.round(weather.main.feels_like)}&deg;F<p>Feels Like</p></div>
            <div className="feels">{weather.main.humidity}%<p>Humidity</p></div>
            <div className="feels">{Math.round(weather.wind.speed)} MPH<p>Wind Speed</p></div>
        </div>
        </div>
        </div>
        </div>
        
      ) : ('')}
      </main>
  </div>
  </body>
  
  );
}

export default WeatherApp;
