import * as React from "react";
import { useState } from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import Home from "./routes/Resume";
import Projects from "./routes/Projects";
import WeatherApp from "./routes/WeatherApp";
import Chatgpt from "./routes/Chatgpt";
import Navbar from "./components/Navbar";
import Quake from "./routes/Quake";
import Subscribe from "./routes/Subscribe";
import Sidebar from "./components/Sidebar";

const AppLayout = () => {
  
    return (
        <>
        
        <Sidebar />
        <Outlet />
        </>
      
    );

    }
const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
    {
    path: "/",
    element: <Home />,
    },
    {
    path: "projects",
    element: <Projects />,
    },
    {
      path: "projects/chatgpt",
      element: <Chatgpt />,
      },
      {
        path: "projects/weather",
        element: <WeatherApp />,
        },
        {
          path: "projects/quake",
          element: <Quake />,
          }, 
          {
            path: "projects/quakesub",
            element: <Subscribe />,
            }
    ],
},
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
  
);
