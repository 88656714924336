import React from 'react';
import cryptoimg from '../images/icons8-crypto-240.png';
import chatgptimg from '../images/icons8-chatgpt-192.png';
import weatherimg from '../images/icons8-heavy-rain-100.png';
import quakeimg from '../images/icons8-destruction-64.png';
import { Link } from 'react-router-dom';
import  './routes.css';
import  './resume.css';
function Projects() {
    
    return (
        <div className="project">
            <center>
            <div className="wrapper">
            <br></br>
            <Link className="links" to="Quake">
            <div className="one">
            <center>
            <h3>Quake Alerts</h3>
            <br></br>
            <img width="100px" height="100px" src={quakeimg}/>
            </center>
            
            </div>
            </Link>
            <Link className="links" to="Chatgpt">
            <div className="two">
            <center><h3>ChatGPT Images</h3>
            <br></br>
                <img width="100px" height="100px" src={chatgptimg}/>
                </center>
            </div>
            </Link>
            <Link className="links" to="Weather">
            <div className="three">
            <center><h3>Weather App</h3>
            <br></br>
            <img width="100px" height="100px" src={weatherimg}/>
            </center>
            </div>
            </Link>
            </div>
        </center>
        <br></br>
        <br></br>
        </div>
    )
}

export default Projects;