import React, {useRef, useState} from 'react';
import "../routes/resume.css";


function Subscribe() {
    const inputRef = useRef(null);
    const [body, setBody] = useState('');
    var mystatus="";
    const handleSubClick = () => {
    if(inputRef.current.value != ""){    
    fetch(`https://t8cozy4r9f.execute-api.us-east-1.amazonaws.com/default/quakesubscribe?email=${inputRef.current.value}`)
    .then((response) => {
       
        console.log("response.status =", response.status); // response.status = 200
        if(response.status===200){
            setBody(`${inputRef.current.value} has been registered! Please check your inbox to confirm your subscription`)
            inputRef.current.value="";
          }else{
            setBody("")
            setBody(`Error: Please check your email:${inputRef.current.value} address and try again!"`)
          }
        return response.body;
      }) }else{
        setBody("")
        setBody("Please verify the email address you entered and try again!")
      }
    
    };
      const handleUnsubClick = () => {
        if(inputRef.current.value != ""){
        fetch(`https://t8cozy4r9f.execute-api.us-east-1.amazonaws.com/default/quakeunsubscribe?email=${inputRef.current.value}`)
        .then((response) => {
           
            console.log("response.status =", response.status); // response.status = 200
            
              setBody(`Please enter an email`)
            
            if(response.status===200){
                setBody(`${inputRef.current.value} has been unsubscribed!`)
                inputRef.current.value="";
              }else{
                setBody("")
                setBody(`No active subscription found for ${inputRef.current.value}. Please check your email address and try again!`)
              }
            return response.body;
          }) }else{
            setBody("")
            setBody("Please verify the email address you entered and try again!")
          }
        
        };
    return(
      <div>
      <div class="w3-teal w3-roboto w3-large">
        <br></br>
        <center>
        <p>An email notification subscription that uses AWS API Gateway, Lambda and SNS to send an email on the hour with USGS quake data.</p>
        </center>
        <br></br>
        </div>
        <div class="w3-roboto">
        <br></br>
        <center><h1>Quake Alert Email Notifications</h1></center>
        <br></br>
      </div>
        <center>
        <b>{body}</b>
        </center>
        <br></br>
    <center><div class="w3-shadowbox w3-roboto">
        <p>Enter your email address:</p>
        
        <input 
        type = "email"
        ref={inputRef}
        placeholder=""
        />
        <br></br>
        
        <br></br>
        <center><input type="button" className="button" value=" Subscribe " onClick={handleSubClick}/>&nbsp;&nbsp;<input type="button" className="button" value=" Unsubscribe " onClick={handleUnsubClick}/></center>
      </div></center>
      </div>
    )
  }
  export default Subscribe;