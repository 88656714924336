import React, { useRef, useState} from 'react';
import './routes.css';
import './resume.css';
const Chatgpt = () => {
  // eslint-disable-next-line
  const [data, setData]=useState([]);
  const [imagetext,setImageText]=useState([]);
  const inputRef = useRef(null);

  const handleClick = () => {

    getData();
  }
    const getData=()=>{
      console.log(inputRef.current.value)
      fetch(`https://ohvu4dg1ec.execute-api.us-east-1.amazonaws.com/prod/imgcreate?request=${inputRef.current.value}`)
      .then(response => {
  
        return response.text()
      })
      .then(data => {
        setData(data)
        
        setImageText(<img id="image" width="300px" and height="300px" src={data} alt="render"></img>)
        console.log(data)
      })
  

  };
  const onClear = () => {
    inputRef.current.value = "";
    setImageText('');
    
  };

return(
  
  <div className="form w3-roboto">
    <form align="center">
      <div class="w3-teal w3-large"><br></br>This code project uses an AWS API gateway that triggers a lambda. That lambda sends the request <p> to the OpenAI image creation API and returns the image.</p><br></br></div>
      <br></br>
      <h3>Describe the image you want chatGPT to create. Image will take about 10 seconds to render.</h3>
      <br></br>
      <textarea size="300" rows="4" columns="50" ref={inputRef} name="message" id="message"/>
      <br></br>
      <br></br>
      <input type="button" value=" Submit Request " onClick={handleClick}/>&nbsp;&nbsp;
      <input type="button" value=" Clear " onClick={onClear}/>
      <br></br>
      <br></br>
      </form>
    <div className="form">
      <center>{imagetext}</center>
      <br></br>
    </div>
    </div>
);

}

export default Chatgpt;