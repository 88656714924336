import React, {useEffect, useState} from 'react';
import L from 'leaflet';
import {MapContainer, TileLayer, GeoJSON} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './weather.css';
import './resume.css';



L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  });
const Quake = () => {
    const [coord] = useState([]);
    const [data, setData] = useState([]);
    const [meta, setMeta] = useState([]);

    const getData=()=>{
        fetch("https://earthquake.usgs.gov/earthquakes/feed/v1.0/summary/all_hour.geojson")
        .then(response => {
          return response.json()
        })
          .then(data => {
            setMeta(data["metadata"])
            setData(data)
            
            

    })}
            
    const onEachFeature = (feature, layer) => {
      
      console.log(coord);
      const popupContent = `<b><u>${feature.properties.title}</u></b></h3> <br> Magnitude: ${feature.properties.mag} <br> Place: ${feature.properties.place}
      <br> Time: ${date.format(feature.properties.time)} <br> <a href=${feature.properties.url}>USGS Detailed Info</a>`;
      if (feature.properties && feature.properties.popupContent) {
        // eslint-disable-next-line
        popupContent += feature.properties.popupContent;
      }
      layer.bindPopup(popupContent);
    };
    useEffect(() => {
        getData()
        setData('')
    }, [])
    const unixdate= meta['generated'];
    console.log(unixdate)
    let date = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    const converteddate=date.format(unixdate);
    console.log(converteddate)
    console.log("meta:",meta)
    console.log("data", data)
return(
<center>
  <div class="w3-roboto w3-margin-bottom">
          <div class="w3-teal w3-large"><br></br>Map that plots the latest Quakes from USGS. This project uses react-leaflet,<p> OpenStreetMap and USGS GeoJSON data.</p>
          <br></br>
          </div>
          <div class="w3-small">
            <p><h2><a href={meta.url}>{meta.title}</a></h2></p>
            <p>Last Generated: {converteddate}</p>
            
            <p><h5><a href="/projects/quakesub">Click here subscribe to email alerts!</a></h5></p>
          </div>
          
          <br></br>
          <MapContainer style={{width: '100%', height: '75vh', position: 'absolute', zindex:'-2000'}} className="leaflet-container" center={[27.739372907153804, -100.02843486967727]} zoom={2} scrollWheelZoom={false}>    
          <GeoJSON data={data} 
          key={JSON.stringify(data)}
          onEachFeature={onEachFeature}></GeoJSON>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />"
          </MapContainer>
         
        </div>
        </center>

);

}      


export default Quake;