import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
{
	title: "About Me",
	path: "/",
	icon: <AiIcons.AiFillHome />,
	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,
},
{
	title: "Projects",
    path: "/projects",
	icon: <IoIcons.IoIosPaper />,
	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,

	subNav: [
	{
		title: "Quake Alert",
		path: "/projects/Quake",
		icon: <RiIcons.RiEarthquakeLine />,
		cName: "sub-nav",
	},
	{
		title: "AI Image Creation",
		path: "/projects/Chatgpt",
		icon: <IoIcons.IoIosImages />,
		cName: "sub-nav",
	},
	{
		title: "Weather App",
		path: "/projects/Weather",
		icon: <RiIcons.RiThunderstormsLine />,
	},
	],
},

];
