import React, { useEffect, useState } from 'react';
import "../routes/resume.css";


function Footer() {
    const [state] = useState(0);
    const [visit, setVisit] = useState([]);
    const [ip, setIP] = useState([]);
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');
  
    
    const url = "https://ip-geo-location.p.rapidapi.com/ip/check";


    useEffect(() => {
      // Run! Like go get some data from an API.
      if(state === 0){
        fetch(url, {
          headers:{
            'X-RapidAPI-Key': '994a4b6549msha5915a4ef39507ep132ca5jsn0bc4c7c3a30f',
            'X-RapidAPI-Host': 'ip-geo-location.p.rapidapi.com'
          }
          })
          .then(response => {
            
            return response.json();

          })
            .then(res => {
              setIP(res.ip);
              setCity(res.city.name);
              setRegion(res.area.name);
            fetch(`https://5hxt7beky4.execute-api.us-east-1.amazonaws.com/default/visitorinfo?city=${res.city.name}&region=${res.area.name}&lat=${res.location.latitude}&lon=${res.location.longitude}&countryc=${res.country.code}`)
            .then(response => {
    
              return response.json();
              
            })
            })
            
     fetch("https://5hxt7beky4.execute-api.us-east-1.amazonaws.com/default/VisitorCounter")
     .then(response3 => {
        return response3.json();
      })
        .then(data => {
          setVisit(data); 
      })
    }else{

  }

    
    }, [state]);
  
    return (
      <>
      
       

        <p class="w3-roboto">Visitor Count: {visit}</p>
        <p class="w3-roboto">IP Address: {ip}</p>
        <p class="w3-roboto">Location: {city}, {region}</p>
      </>
    );
  }

    
      
export default Footer;